import React from "react";
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Home from "./routes/home";
import Resume from "./routes/resume";

function App() {
  return (
      <Router>
        <Routes>
            <Route path="/" element={<Home />}/>
            <Route path="/resume" element={<Resume/>} />

            <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
  );
}

export default App;
