import { useEffect } from "react";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyCSmIERFACNeMrPK0XkuJ_G4snFKQSZIDs",
    authDomain: "skylercc-453b3.firebaseapp.com",
    projectId: "skylercc-453b3",
    storageBucket: "skylercc-453b3.appspot.com",
    messagingSenderId: "1038941993400",
    appId: "1:1038941993400:web:b4f8b4e36471521129f74d",
    measurementId: "G-QRQK32P4XX"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


export default function Resume() {
    useEffect(() => {
        window.location.replace('/static/resume.pdf');

    }, []);

    return null;
}
