import React, { useEffect, useState } from 'react';
import '../css/table.css'

const Table = ({ title, jsonPath }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(jsonPath);
                const result = await response.json();
                setData(result);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [jsonPath]);

    const handleClick = (link) => {
        window.open(link, '_blank');
    };

    return (
        <div className="table">
            <h2>{title}</h2>
            <div className="table-container">
                {data.map((item, index) => (
                    <div
                        key={index}
                        className="table-row"
                        onClick={() => handleClick(item.link)}
                    >
                        <img
                            src={item.image}
                            alt={item.title}
                            className="table-image"
                            width="40"
                        />
                        <div className="table-content">
                            <div className="table-title">{item.title}</div>
                            <div className="table-subtitle">{item.subtitle}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Table;
